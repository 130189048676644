<template>
    <div class="row justify-content-center h-100 align-items-center mt-4">
        <div class="col-md-6">
            <div class="authincation-content">
                <div class="row no-gutters">
                    <div class="col-xl-12">
                        <div class="auth-form" ref="resetPass">
                            <div class="text-center mb-3">
                                <a href="/">
                                    <img src="../../assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <h4 class="text-center mb-4">Reset your password</h4>
                            <template v-if="error">
                                <div class="col-12 pt-5" style="height: 200px">
                                   <h4 class="text-center">{{message}}</h4>
                                </div>
                            </template>
                            <form @submit.prevent="validateInput" v-else>
                                <div class="form-group">
                                    <label class="mb-1"><strong>New Password</strong></label>
                                    <input type="password" class="form-control" v-model="password" placeholder="***************">
                                </div>
                                <div class="form-group">
                                    <label class="mb-1"><strong>Retype Password</strong></label>
                                    <input type="password" class="form-control" v-model="repass" placeholder="***************">
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary btn-block">Update password</button>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                                <p>
                                    <router-link class="text-primary" :to="{name: 'Login'}">back to login</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "ResetPassword",
    data(){
        return {
            error: true,
            password: '',
            repass: '',
            user_id: '',
            message: 'Loading...please wait'
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            const loader = this.$loading.show({container: this.$refs.resetPass});
            const {password} = this;
            let response = await this.$store.dispatch('auth/updatePassword', {
                email: this.$route.params.email,
                password
            });
            loader.hide();
            if(response.status){
                await this.$store.dispatch('user/updateUser', {
                    userId: this.user_id, userData: {password_reset_ref: ''}
                })
                toastr.success("Password updated!")
                return this.$router.push({name: 'Login'})
            }else{
                return toastr.error(response.message, 'Error')
            }
        },
        validateInput(){
            if(!(this.password === this.repass)){
                return toastr.warning("Passwords do not match!", "Oops!")
            }
            if(this.password.length < 8){
                return toastr.warning("The password length should be greater than 7 characters", "Oops!")
            }
            return this.proceed()
        },
        async testLink(){
            const {email, passRef} = this.$route.params;
            const loader = this.$loading.show({container: this.$refs.resetPass});
            let response = await this.$store.dispatch('user/getUserByEmail', email);
            if(response.status){
                if(passRef === response.data.data.password_reset_ref){
                    this.user_id = response.data.id;
                    this.error = false;
                }else{
                    // this.error = true;
                    this.message = "An unknown error occurred"
                }
            }else{
                // this.error = true;
                this.message = response.message;
            }
            loader.hide();
        }
    },
    mounted() {
        this.testLink()
    }
}
</script>

<style scoped>

</style>